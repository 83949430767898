import { DocumentReportIcon, DownloadIcon } from '@heroicons/react/outline'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { signout } from '../redux/auth/authActions'
import { changeCompany, changeCurrentStatus, setValuesToDefaultManager, toggleShowList } from '../redux/manager/managerActions'
import Expiring from './Expiring'
import GlobalSearch from './GlobalSearch'
import Notification from './Notification'

const Image = 'data:image/png;base64,...'; // оставьте ваш оригинальный base64

function Header(props) {
  const signoutHandler = () => {
    props.signout()
    props.history.push("/login")
    props.setValuesToDefaultManager()
  }

  const changeCompanyHandler = () => {
    localStorage.removeItem('company_id')
    props.history.push("/")
    props.changeCompany()
    props.changeCurrentStatus()
    props.toggleShowList()
  }

  const managerName = useMemo(() => {
    let fullName = localStorage.getItem('manager_name')
    let name = fullName.split(" ")
    return name.slice(0, 2).join(' ')
  })

  const downloadReport = async () => {
    try {
      const response = await fetch('/expiring_profiles_report')
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'expiring_profiles.xlsx'
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Ошибка при загрузке отчета:', error)
    }
  }

  return (
      <header className="text-gray-600 body-font bg-white shadow-md mb-4">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <img onClick={() => props.history.push("/")} alt="company" style={{ width: 30, height: 30 }} className="object-fill w-32 object-center flex-shrink-0 mr-2 cursor-pointer" src={Image} />
            <span onClick={() => props.history.push("/settings")} className="ml-3 text-lg hover:text-gray-900 cursor-pointer border-b border-gray-800 border-opacity-0 transition duration-300 ease-in-out hover:border-opacity-100">{managerName}</span>
          </div>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400 flex flex-wrap items-center text-base justify-center">
            <div onClick={changeCompanyHandler} className="hover:text-gray-900 cursor-pointer border-b border-gray-800 border-opacity-0 transition duration-300 ease-in-out hover:border-opacity-100">{localStorage.getItem('company_name')}</div>
          </nav>
          <div className="relative">
            <div className="flex items-center">
              <button
                  onClick={downloadReport}
                  className="mr-1 h-7 w-7 mt-2 md:mt-0 py-1 px-1 flex justify-center items-center hover:bg-gray-600 focus:ring-gray-500 focus:ring-offset-gray-200 hover:text-white text-gray-800 transition ease-in duration-200 text-center text-base font-semibold hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 rounded"
              >
                <DownloadIcon className="w-5 h-5" />
              </button>
              <button
                  onClick={() => props.history.push("/revise_table")}
                  className="mr-1 h-7 w-7 mt-2 md:mt-0 py-1 px-1 flex justify-center items-center hover:bg-gray-600 focus:ring-gray-500 focus:ring-offset-gray-200 hover:text-white text-gray-800 transition ease-in duration-200 text-center text-base font-semibold hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 rounded"
              >
                <DocumentReportIcon className="w-5 h-5" />
              </button>
              <div className="h-5 mt-2 md:mt-0 mr-2">
                <Notification />
              </div>
              <div className="mr-1 h-5 mt-2 md:mt-0">
                <Expiring />
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center ml-2 my-4 md:my-0 md:mx-3">
            <GlobalSearch />
          </div>
          <button onClick={signoutHandler} className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base">
            Выйти
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </header>
  )
}

const mapDispatchToProps = {
  changeCompany,
  signout,
  toggleShowList,
  changeCurrentStatus,
  setValuesToDefaultManager
}

export default connect(null, mapDispatchToProps)(withRouter(Header))