import {
  ADD_FILE_TOKEN,
  HIDE_LOADER,
  CHANGE_DID_NOT_WORK,
  CHANGE_ONLY_SCHOOL,
  CHANGE_PATENT_SHOW,
  SHOW_NONE,
  CHANGE_STAGE,
  CHANGE_TO_BACK_STAGE,
  DELETE_FILES,
  FETCH_DATA,
  FILE_LOADER,
  HIDE_ALERT,
  LOG_OUT,
  SET_FILES,
  SHOW_ALERT,
  SHOW_LOADER,
  SHOW_RVP,
  SHOW_SECTION_NINE,
  SHOW_VNG,
  SIGN_IN,
  SHOW_STUDY,
  SET_COUNTRY,
  FILE_ADD,
  INC_COUNT_SENT_FILES,
  FILE_REMOVE,
  FILE_CLEAR
} from "./types";
import React, { useReducer } from "react";

import { FormContext } from "./FormContext";
import backendService from "../../services/backendService";
import { formReducer } from "./formReducer";
import axios from "axios";
import { citizenshipSelect } from "../../services/helperVars";
// import axios from "axios";
// salaryPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5",],
//   'Выберите один из вариантов'
// ),
// collectivePriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5",],
//   'Выберите один из вариантов'
// ),
// collect: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5",],
//   'Выберите один из вариантов'
// ),
// stabilityPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5",],
//   'Выберите один из вариантов'
// ),
// perspectivePriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5",],
//   'Выберите один из вариантов'
// ),
// socialSecurityPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5",],
//   'Выберите один из вариантов'
// ),
// decencyPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
// sustainabilityPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
// efficiencyPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
// optimismPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
// informationPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
// prestigePriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
// comfortPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
// individualityPriority: Yup.string()
// .oneOf(
//   ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
//   'Выберите один из вариантов'
// ),
const fileTypes= {
  "Скан паспорта": "f31c7dd6-cfc1-4124-ab13-6eab66936aed",
  "Скан регистрации": "d4c154e1-4f09-430d-875e-476f4ec72220",
  "Скан миграционной карты": "0dba0e6e-d30d-4a61-a15a-20b66094010e",
  "Скан патента": "d6391911-f4d3-41a5-ac62-b9d8a82d7fe5",
  "Скан РВП": "ddbc9462-86f5-11e5-b4b4-002590e93573",
  "Скан ВНЖ": "c301a208-40fc-4783-acb5-01fc4845e27d",
}

export const FormState = ({ children }) => {
  const initialState = {
    countSentFiles: 0,
    currentPageIndex: 0,
    token: '',
    data: [],
    files: [],
    country: '',
    loading: false,
    showPatent: false,
    showNone: false,
    showRvp: false,
    showVng: false,
    showStudy: false,
    alertVisible: false,
    showSectionNine: false,
    onlySchool: false,
    didNotWork: false,
    fileTokens: [],
    fileLoader: false,
    filesData: [],
  };
//   fileInputPassport: Yup.mixed()
//   .required("Необходимо добавить файлы")
//   .test(
//     "fileSize",
//     "Файл слишком много весит",
//     value => value && value.size <= 1024 * 1024
//   )
//   .test(
//     "fileFormat",
//     "Неподдерживаемый формат",
//     value => value && [ "image/jpg", "image/jpeg", "image/png", "application/pdf" ].includes(value.type)
//   ),
// fileInputRegistration: Yup.mixed()
//   .required("Необходимо добавить файлы")
//   .test(
//     "fileSize",
//     "Файл слишком много весит",
//     value => console.log(value)
//   )
//   .test(
//     "fileFormat",
//     "Неподдерживаемый формат",
//     value => value && [ "image/jpg", "image/jpeg", "image/png", "application/pdf" ].includes(value.type)
//   ),

  const [state, dispatch] = useReducer(formReducer, initialState);


  const fetchData = async (formId = "d500760b-20c4-4b34-93b2-2c57545bcfb9", country) => {
    // showLoader()

    // для form и miniForm
    // каждый объект это страница
    // у страницы есть inputs и validation для них
    let payload = [
      {
        name: "Выбор гражданства",
        id: "1",
        validation: `
          citizenship: Yup.string()
                .oneOf(
                  ["Киргизская Республика", "Республика Узбекистан", "Республика Таджикистан", "Республика Армения", "Республика Казахстан", "Украина", "Республика Беларусь", "Республика Азербайджан", "Республика Молдова", "Туркменистан", "Российская Федерация", "Афганистан"],
                  'Не выбрано гражданство'
                )
                .required('Необходимо заполнить'),
        `,

        inputs: [
          {
            label: "Гражданство",
            initialValue: "",
            id: "100",
            config: {
              name: "citizenship",
              type: "select",
              options: citizenshipSelect
            }
          },
        ]
      },
      {
        name: "Общие сведения",
        id: "300",
        validation: `
              firstName: Yup.string()
                .min(2, 'Слишком мало символов')
                .max(20, "Слишком много букв, введите до 20 символов")
                .required("Необходимо заполнить"),
              lastName: Yup.string()
                .min(2, 'Слишком мало символов')
                .max(25, "Слишком много букв, введите до 25 символов")
                .required("Необходимо заполнить"),
              birthplace: Yup.string()
                .min(5, 'Слишком мало символов')
                .required("Необходимо заполнить"),
              birthDate: Yup.string()
                .required("Необходимо заполнить")
                .test("mask", "Заполните полностью", value => {
                  if (value !== undefined) {
                    if (value.indexOf('_') !== -1) {
                      return false
                    } else {
                      return true
                    }
                  }
                })
                .test("birthDate", "Возраст меньше 18 лет", value => {
                  if (value !== undefined) {
                    return new Date().getFullYear() - value.split('.')[2] >= 18; 
                  }
                }),
              job: Yup.string()
                .notOneOf(
                  ["999999"],
                  'Выберите один из вариантов выше'
                )
                .required('Необходимо заполнить'),
              workplace_id: Yup.string()
                  .notOneOf(
                    ["999999"],
                    'Выберите один из вариантов выше'
                  )
                .required("Необходимо заполнить"),
              phoneNumber: Yup.string()
                .required("Необходимо заполнить"),
              fileInputPhoto: Yup.array()
                .required('Необходимо добавить файлы')
                .test('is-correct-file', 'Слишком много весит', (files) => {
                  let valid = true
                  if (files) {
                    files.map(file => {
                      const size = file.size / 1024 / 1024
                      if (size > 10) {
                        valid = false
                      }
                    })
                  }
                  return valid
                })
                .test(
                  'is-big-file',
                  'Неправильный формат файла',
                  (files)=>{
                    let valid = true
                    if (files) {
                      files.map(file => {
                        if (!['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                          valid = false
                        }
                      })
                    }
                    return valid
                  }
                ),
        `,

        inputs: [
          {
            label: "Фамилия *",
            initialValue: "",
            id: "102",
            config: {
              name: "lastName",
              type: "text",
            }
          },
          {
            label: "Имя *",
            initialValue: "",
            id: "103",
            config: {
              name: "firstName",
              type: "text",
            }
          },
          {
            label: "Отчество",
            initialValue: "",
            id: "104",
            config: {
              name: "patronymic",
              type: "text",
              placeholder: ""
            }
          },
          {
            label: "Прикрепите свою фотографию. Фотография должна быть без каких-либо аксессуаров. Анфас *",
            initialValue: "",
            id: "101",
            config: {
              name: "fileInputPhoto",
              type: "file",
            }
          },
          {
            label: "Место рождения *",
            initialValue: "",
            id: "105",
            config: {
              name: "birthplace",
              type: "text",
            }
          },
          {
            label: "Дата рождения *",
            initialValue: "",
            id: "106",
            config: {
              name: "birthDate",
              type: "date"
            }
          },
          {
            label: "Номер телефона *",
            initialValue: "",
            id: "113",
            config: {
              name: "phoneNumber",
              type: "tel",
            }
          },
          {
            label: "Должность *",
            initialValue: "",
            id: "115",
            config: {
              name: "job",
              type: "select",
              options: [{id: "999999", name: "Выберите один из вариантов"}]
            }
          },
          {
            label: "Номер ТТ *",
            initialValue: "",
            id: "116",
            config: {
              name: "workplace_id",
              type: "select",
              options: [{id: "999999", name: "Выберите один из вариантов"}]
            }
          },

        ]
      },
      {
        name: "Паспорт",
        id: "1",
        validation: `
              passportNumber: Yup.number()
                .typeError('Введите число')
                .required("Необходимо заполнить")
                .positive("Неправильный формат номера")
                .integer("Неправильный формат номера"),
              passportDate: Yup.string()
                .required("Необходимо заполнить")
                .test("passportDate", "Заполните полностью", value => {
                  if (value !== undefined) {
                    if (value.indexOf('_') !== -1) {
                      return false
                    } else {
                      return true
                    }
                  }
                })
                .test("passportDate", "Некорректная дата", value => {
                  if (value !== undefined) {
                    return new Date().getFullYear() - value.split('.')[2] <= 40; 
                  }
                })
                .test("passportDate", "Дата не может быть больше текущей", value => {
                  if (value !== undefined) {
                    const [day, month, year] = value.split('.');
                    const currentDate = new Date();
                    const selectedDate = new Date(year, month - 1, day);
                    return selectedDate <= currentDate;
                  }
                }),
              passportRecieve: Yup.string()
                .required("Необходимо заполнить"),
              fileInputPassport: Yup.array()
                .required('Необходимо добавить файлы')
                .test('is-correct-file', 'Слишком много весит', (files) => {
                  let valid = true
                  if (files) {
                    files.map(file => {
                      const size = file.size / 1024 / 1024
                      if (size > 10) {
                        valid = false
                      }
                    })
                  }
                  return valid
                })
                .test(
                  'is-big-file',
                  'Неправильный формат файла',
                  (files)=>{
                    let valid = true
                    if (files) {
                      files.map(file => {
                        if (!['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                          valid = false
                        }
                      })
                    }
                    return valid
                  }
                ),
              
          `,
        inputs: [
          {
            label: "Серия паспорта",
            initialValue: "",
            id: "107",
            config: {
              name: "passportSeries",
              type: "text",
            }
          },
          {
            label: "Номер паспорта *",
            initialValue: "",
            id: "108",
            config: {
              name: "passportNumber",
              type: "text",
            }
          },
          {
            label: "Дата выдачи паспорта *",
            initialValue: "",
            id: "109",
            config: {
              name: "passportDate",
              type: "date"
            }
          },
          {
            label: "Кем выдан *",
            initialValue: "",
            id: "110",
            config: {
              name: "passportRecieve",
              type: "text",
            }
          },

          {
            label: "Прикрепите скан паспорта. Лицевую и обратную сторону. *",
            initialValue: "",
            id: "112",
            config: {
              name: "fileInputPassport",
              type: "file",
            }
          },
          // {
          //   label: "Должность *",
          //   initialValue: "",
          //   id: "17",
          //   config: {
          //     name: "job",
          //     type: "select",
          //     options: [
          //       {
          //         id: 0,
          //         name: "Выберите один из вариантов"
          //       },
          //       {
          //         id: 1,
          //         name: "Курьер"
          //       },
          //       {
          //         id: 2,
          //         name: "Старший курьер"
          //       },
          //       {
          //         id: 3,
          //         name: "Уборщица"
          //       },
          //       {
          //         id: 4,
          //         name: "Грузчик"
          //       },
          //       {
          //         id: 5,
          //         name: "Промоутер"
          //       },
          //       {
          //         id: 6,
          //         name: "Повар"
          //       },
          //       {
          //         id: 7,
          //         name: "Менеджер"
          //       },
          //       {
          //         id: 8,
          //         name: "Рабочий склада"
          //       },
          //       {
          //         id: 9,
          //         name: "Администратор"
          //       }
          //     ]
          //   }
          // },
          // {
          //   label: "Номер ТТ *",
          //   initialValue: "",
          //   id: "1632",
          //   config: {
          //     name: "jobAddress",
          //     type: "numberTT",
          //     placeholder: "Уточняйте у администратора",
          //   }
          // }
        ]
      },
      {
        name: "Регистрация",
        id: "2",
        validation: `
            registration_city: Yup.string()
                .required("Необходимо заполнить"),
              registration_street: Yup.string()
                .required("Необходимо заполнить"),
              registration_house: Yup.string()
                .required("Необходимо заполнить"),
              registration_room: Yup.string()
                .required("Необходимо заполнить"),
              stayDate: Yup.string()
                .required("Необходимо заполнить")
                .test("stayDate", "Заполните полностью", value => {
                  if (value !== undefined) {
                    if (value.indexOf('_') !== -1) {
                      return false
                    } else {
                      return true
                    }
                  }
                })
                .test("stayDate", "Некорректная дата", value => {
                  if (value !== undefined) {
                    return new Date().getFullYear() - value.split('.')[2] <= 40; 
                  }
                })
                .test("stayDate", "Дата не может быть больше текущей", value => {
                  if (value !== undefined) {
                    const [day, month, year] = value.split('.');
                    const currentDate = new Date();
                    const selectedDate = new Date(year, month - 1, day);
                    return selectedDate <= currentDate;
                  }
                }),
                fileInputRegistration: Yup.array()
                .required('Необходимо добавить файлы')
                .test('is-correct-file', 'Слишком много весит', (files) => {
                  let valid = true
                  if (files) {
                    files.map(file => {
                      const size = file.size / 1024 / 1024
                      if (size > 10) {
                        valid = false
                      }
                    })
                  }
                  return valid
                })
                .test(
                  'is-big-file',
                  'Неправильный формат файла',
                  (files)=>{
                    let valid = true
                    if (files) {
                      files.map(file => {
                        if (!['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                          valid = false
                        }
                      })
                    }
                    return valid
                  }
                ),
          `,

        inputs: [
          {
            label: "Город *",
            initialValue: "",
            id: "117",
            config: {
              name: "registration_city",
              type: "text",
            }
          },
          {
            label: "Улица *",
            initialValue: "",
            id: "118",
            config: {
              name: "registration_street",
              type: "text",
            }
          },
          {
            label: "Дом *",
            initialValue: "",
            id: "119",
            config: {
              name: "registration_house",
              type: "text",
            }
          },
          {
            label: "Корпус/строение",
            initialValue: "",
            id: "120",
            config: {
              name: "registration_housing",
              type: "text",
            }
          },
          {
            label: "Квартира *",
            initialValue: "",
            id: "121",
            config: {
              name: "registration_room",
              type: "text",
            }
          },
          {
            label: "Дата регистрации *",
            initialValue: "",
            id: "122",
            config: {
              name: "stayDate",
              type: "date"
            }
          },
          {
            label: "Прикрепите скан регистрации. Лицевую и обратную сторону. *",
            initialValue: "",
            id: "123",
            config: {
              name: "fileInputRegistration",
              type: "file",
            }
          },
        ],
      },
    ];

    // Получив город, в зависимости от формы (mainForm, miniForm) и значений в БД (табл. country)
    // мы добавляем страницы
    if (country) {
      if (country?.name === "Российская Федерация") {
        payload[payload.findIndex(i => i.name === "Паспорт")]['inputs'].push(
            {
              label: "Код подразделения",
              initialValue: "",
              id: "111",
              config: {
                name: "departmentCode",
                type: "text",
                placeholder: "При наличие",
              }
            },
        )

      }
      // для mainForm и miniForm
      if (formId && country?.name !== 'Российская Федерация') {
        payload[payload.findIndex(i => i.name === "Паспорт")]['inputs'].push(
            {
              label: "Срок действия паспорта *",
              initialValue: "",
              id: "129",
              config: {
                name: "passportDateEnd",
                type: "date"
              }
            }
        )
        payload[payload.findIndex(i => i.name === "Паспорт")]['validation'] += `
                        passportDateEnd: Yup.string()
                                .required("Необходимо заполнить")
                                .test("passportDateEnd", "Заполните полностью", value => {
                                    if (value !== undefined) {
                                        if (value.indexOf("_") !== -1) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                })
                                .test("passportDateEnd", "Дата не может быть меньше текущей", value => {
                                    if (value !== undefined) {
                                        const [day, month, year] = value.split(".");
                                        const currentDate = new Date();
                                        const selectedDate = new Date(year, month - 1, day);
                                        return selectedDate >= currentDate;
                                    }
                                }),
        `
      }

      // для mainForm и miniForm
      if (formId && country?.is_register) {
        payload[payload.findIndex(i => i.name === "Регистрация")]['inputs'].push({
          label: "Дата окончания регистрации *",
          initialValue: "",
          id: "130",
          config: {
            name: "stayDateEnd",
            type: "date"
          }
        })
        payload[payload.findIndex(i => i.name === "Регистрация")]['validation'] += `
                        stayDateEnd: Yup.string()
                                .required("Необходимо заполнить")
                                .test("stayDateEnd", "Заполните полностью", value => {
                                    if (value !== undefined) {
                                        if (value.indexOf("_") !== -1) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                })
                                .test("stayDateEnd", "Дата не может быть меньше текущей", value => {
                                    if (value !== undefined) {
                                        const [day, month, year] = value.split(".");
                                        const currentDate = new Date();
                                        const selectedDate = new Date(year, month - 1, day);
                                        return selectedDate >= currentDate;
                                    }
                                }),
        `
      }

      // для mainForm и miniForm
      // if (formId && country?.is_polis) {
      //   payload.push({
      //     name: "Полис ДМС",
      //     id: "4",
      //     validation: `
      //                   fileInputPolice: Yup.array()
      //                           .required("Необходимо добавить файлы")
      //                           .test("is-correct-file", "Слишком много весит", files => {
      //                               let valid = true;
      //                               if (files) {
      //                                   files.forEach(file => {
      //                                       const size = file.size / 1024 / 1024;
      //                                       if (size > 10) {
      //                                           valid = false;
      //                                       }
      //                                   });
      //                               }
      //                               return valid;
      //                           })
      //                           .test("is-big-file", "Неправильный формат файла", files => {
      //                               let valid = true;
      //                               if (files) {
      //                                   files.forEach(file => {
      //                                       if (
      //                                           ![
      //                                               "application/pdf",
      //                                               "image/jpeg",
      //                                               "image/jpg",
      //                                               "image/png"
      //                                           ].includes(file.type)
      //                                       ) {
      //                                           valid = false;
      //                                       }
      //                                   });
      //                               }
      //                               return valid;
      //                           }),
      //                   polisSeries: Yup.string()
      //                           .required("Необходимо заполнить"),
      //                   polisNumber: Yup.number()
      //                           .typeError("Введите число")
      //                           .positive("Неправильный формат серии")
      //                           .integer("Неправильный формат серии")
      //                           .required("Необходимо заполнить"),
      //                   polisNumberBlank: Yup.number()
      //                           .typeError("Введите число")
      //                           .positive("Неправильный формат серии")
      //                           .integer("Неправильный формат серии")
      //                           .required("Необходимо заполнить"),
      //                   polisRecieve: Yup.string()
      //                           .required("Необходимо заполнить"),
      //                   polisDateStart: Yup.string()
      //                           .required("Необходимо заполнить"),
      //                   polisDateFrom: Yup.string()
      //                           .required("Необходимо заполнить"),
      //                   polisDateEnd: Yup.string()
      //                           .required("Необходимо заполнить")
      //                           .test("polisDateEnd", "Заполните полностью", value => {
      //                               if (value !== undefined) {
      //                                   if (value.indexOf("_") !== -1) {
      //                                       return false;
      //                                   } else {
      //                                       return true;
      //                                   }
      //                               }
      //                           })
      //                           .test("polisDateEnd", "Дата не может быть меньше текущей", value => {
      //                               if (value !== undefined) {
      //                                   const [day, month, year] = value.split(".");
      //                                   const currentDate = new Date();
      //                                   const selectedDate = new Date(year, month - 1, day);
      //                                   return selectedDate >= currentDate;
      //                               }
      //                           }),
      //     `,
      //
      //     inputs: [
      //       {
      //         label: "Серия *",
      //         initialValue: "",
      //         id: "135",
      //         config: {
      //           name: "polisSeries",
      //           type: "text"
      //         }
      //       },
      //       {
      //         label: "Номер *",
      //         initialValue: "",
      //         id: "136",
      //         config: {
      //           name: "polisNumber",
      //           type: "text"
      //         }
      //       },
      //       {
      //         label: "Номер бланка *",
      //         initialValue: "",
      //         id: "720",
      //         config: {
      //           name: "polisNumberBlank",
      //           type: "text"
      //         }
      //       },
      //       {
      //         label: "Кем выдан *",
      //         initialValue: "",
      //         id: "137",
      //         config: {
      //           name: "polisRecieve",
      //           type: "text"
      //         }
      //       },
      //       {
      //         label: "Дата выдачи: от *",
      //         initialValue: "",
      //         id: "138",
      //         config: {
      //           name: "polisDateStart",
      //           type: "date"
      //         }
      //       },
      //       {
      //         label: "Срок действия: с *",
      //         initialValue: "",
      //         id: "139",
      //         config: {
      //           name: "polisDateFrom",
      //           type: "date"
      //         }
      //       },
      //       {
      //         label: "Срок действия: по *",
      //         initialValue: "",
      //         id: "700",
      //         config: {
      //           name: "polisDateEnd",
      //           type: "date"
      //         }
      //       },
      //       {
      //         label: "Прикрепите скан полиса ДМС. *",
      //         initialValue: "",
      //         id: "140",
      //         config: {
      //           name: "fileInputPolice",
      //           type: "file"
      //         }
      //       },
      //     ],
      //   })
      // }

      // для mainForm и miniForm
      if (formId && (country?.is_rvp || country?.is_study || country?.is_vng || country?.is_patent || country?.is_other)) {
        payload.push({
          name: "Иные документы",
          id: "5",
          validation: ``,

          inputs: [],
        })
      }

      // для mainForm
      if (formId === "d500760b-20c4-4b34-93b2-2c57545bcfb9") {
        payload.push(
          {

            name: "Вакансия",
            id: "6",
            validation: `
            vacancySource: Yup.string()
                .oneOf(
                  ["HeadHunter", "Зарплата.ру", "SuperJob", "VK", "Авито", "Worki", "Работал у нас", "Другое (знакомый)"],
                  'Выберите один из пунктов выше'
                )
                .required('Необходимо заполнить'),
              `,

            inputs: [
              {
                label: "Укажите, пожалуйста, откуда Вы узнали о нашей вакансии*",
                initialValue: "",
                id: "141",
                config: {
                  name: "vacancySource",
                  type: "adv",
                  variants: [
                    {
                      id: '1',
                      name: 'HeadHunter'
                    },
                    {
                      id: '2',
                      name: 'Зарплата.ру'
                    },
                    {
                      id: '3',
                      name: 'SuperJob'
                    },
                    {
                      id: '4',
                      name: 'VK'
                    },
                    {
                      id: '5',
                      name: 'Авито'
                    },
                    {
                      id: '6',
                      name: 'Worki'
                    },
                    {
                      id: '7',
                      name: 'Работал у нас'
                    },
                    {
                      id: '9',
                      name: 'Другое (знакомый)'
                    },
                  ]
                }
              },
            ],
          },
          {
            name: "Общий",
            id: "7",
            validation: `
          familyStatus: Yup.string()
            .oneOf(
              ["0", "1"],
              'Выберите один из вариантов выше'
            )
            .required('Необходимо заполнить'),
          sex: Yup.string()
            .oneOf(
              ["0", "1"],
              'Не выбран пол'
            )
            .required('Необходимо заполнить'),
          residence_city: Yup.string()
            .required("Необходимо заполнить"),
          residence_street: Yup.string()
            .required("Необходимо заполнить"),
          residence_house: Yup.string()
            .required("Необходимо заполнить"),
          residence_room: Yup.string()
            .required("Необходимо заполнить"),
          conviction: Yup.string()
            .oneOf(
              ["0", "1", "2"],
              'Выберите один из вариантов выше'
            )
            .required('Необходимо заполнить'),
          smoking: Yup.string()
            .oneOf(
              ["0", "1"],
              'Выберите один из вариантов выше'
            )
            .required('Необходимо заполнить'),
          drivingLicens: Yup.string()
            .oneOf(
              ["0", "1"],
              'Выберите один из вариантов выше'
            )
            .required('Необходимо заполнить'),
          ownCar: Yup.string()
            .oneOf(
              ["0", "1"],
              'Выберите один из вариантов выше'
            )
            .required('Необходимо заполнить'),
          `,
            inputs: [
              {
                label: "Семейное положение *",
                initialValue: "",
                id: "142",
                config: {
                  name: "familyStatus",
                  type: "radio",
                  variants: [
                    {
                      id: "1",
                      name: "Холост / Не замужем"
                    },
                    {
                      id: "2",
                      name: "Женат / Замужем"
                    }
                  ]
                }
              },
              {
                label: "Пол *",
                initialValue: "",
                id: "143",
                config: {
                  name: "sex",
                  type: "radio",
                  variants: [
                    {
                      id: "1",
                      name: "М"
                    },
                    {
                      id: "2",
                      name: "Ж"
                    }
                  ]
                }
              },
              {
                label: "Адрес фактического проживания: Город *",
                initialValue: "",
                id: "144",
                config: {
                  name: "residence_city",
                  type: "text",
                }
              },
              {
                label: "Адрес фактического проживания: Улица *",
                initialValue: "",
                id: "145",
                config: {
                  name: "residence_street",
                  type: "text",
                }
              },
              {
                label: "Адрес фактического проживания: Дом *",
                initialValue: "",
                id: "146",
                config: {
                  name: "residence_house",
                  type: "text",
                }
              },
              {
                label: "Адрес фактического проживания: Корпус",
                initialValue: "",
                id: "147",
                config: {
                  name: "residence_housing",
                  type: "text",
                }
              },
              {
                label: "Адрес фактического проживания: Квартира *",
                initialValue: "",
                id: "148",
                config: {
                  name: "residence_room",
                  type: "text",
                }
              },
              {
                label: "Email",
                initialValue: "",
                id: "149",
                config: {
                  name: "email",
                  type: "text",
                }
              },
              {
                label: "Ссылка на страницу в социальных сетях",
                initialValue: "",
                id: "150",
                config: {
                  name: "link",
                  type: "text",
                }
              },
              {
                label: "Наличие судимости (у вас или у ваших родственников) *",
                initialValue: "",
                id: "151",
                config: {
                  name: "conviction",
                  type: "conviction",
                  variants: [
                    {
                      id: "2",
                      name: "Нет"
                    },
                    {
                      id: "1",
                      name: "Да"
                    },
                    {
                      id: "3",
                      name: "Судимость погашена",
                    }
                  ]
                }
              },
              {
                label: "Курите ли вы *",
                initialValue: "",
                id: "152",
                config: {
                  name: "smoking",
                  type: "radio",
                  variants: [
                    {
                      id: "2",
                      name: "Нет"
                    },
                    {
                      id: "1",
                      name: "Да"
                    },
                  ]
                }
              },
              {
                label: "Имеете ли водительские права *",
                initialValue: "",
                id: "153",
                config: {
                  name: "drivingLicens",
                  type: "radio",
                  variants: [
                    {
                      id: "2",
                      name: "Нет"
                    },
                    {
                      id: "1",
                      name: "Да"
                    },
                  ]
                }
              },
              {
                label: "Имеете ли личный автомобиль *",
                initialValue: "",
                id: "154",
                config: {
                  name: "ownCar",
                  type: "radio",
                  variants: [
                    {
                      id: "2",
                      name: "Нет"
                    },
                    {
                      id: "1",
                      name: "Да"
                    },
                  ]
                }
              },
            ]
          },
          {
            name: "Квалификация",
            id: "8",
            validation: ``,
            inputs: [
              {
                label: "Какие учебные заведения вы окончили и когда",
                initialValue: "",
                id: "155",
                config: {
                  name: "onlySchool",
                  type: "checkbox",
                  placeholder: "Окончил(а) только школу"
                }
              },
              {
                id: "156",
                config: {
                  type: "education",
                }
              }
            ]
          },
          {
            name: "Деятельность",
            id: "9",
            validation: `
            prevSalary: Yup.string()
              .required("Необходимо заполнить"),
            requestedSalary: Yup.string()
              .required("Необходимо заполнить"),
            newWorkStartDate: Yup.string()
              .required("Необходимо заполнить")
              .test("newWorkStartDate", "Заполните полностью", value => {
                if (value !== undefined) {
                  if (value.indexOf('_') !== -1) {
                    return false
                  } else {
                    return true
                  }
                }
              }),
          `,
            inputs: [
              {
                label: "Опыт работы (укажите опыт работы, начиная с последнего места)*",
                initialValue: "",
                id: "157",
                config: {
                  name: "didNotWork",
                  type: "checkbox",
                  placeholder: "Ранее не работал(ла) — без опыта работы"
                }
              },
              {
                initialValue: "",
                id: "158",
                config: {
                  type: "work",
                }
              },
              {
                label: "Укажите, пожалуйста, размер вашей з/пл на последнем месте работы (в руб.) *",
                initialValue: "",
                id: "159",
                config: {
                  name: "prevSalary",
                  type: "text",
                }
              },
              {
                label: "На какую з/пл Вы претендуете (в руб.) *",
                initialValue: "",
                id: "160",
                config: {
                  name: "requestedSalary",
                  type: "text",
                }
              },
              {
                label: "Когда Вы готовы приступить к работе? (дата) *",
                initialValue: "",
                id: "161",
                config: {
                  name: "newWorkStartDate",
                  type: "date",
                }
              },
            ]
          },
          {
            name: "Сведения",
            id: "10",
            validation: `
            relationDegreeRelatives: Yup.string()
              .required("Необходимо заполнить"),
            nameRelatives: Yup.string()
              .required("Необходимо заполнить"),
            cityRelatives: Yup.string()
              .required("Необходимо заполнить"),
            phoneRelatives: Yup.string()
              .required("Необходимо заполнить"),
            jobPlaceRelatives: Yup.string()
              .required("Необходимо заполнить"),
            jobRelatives2: Yup.string()
              .required("Необходимо заполнить"),
              relationDegreeRelatives: Yup.string()
              .required("Необходимо заполнить"),
            nameRelatives2: Yup.string()
              .required("Необходимо заполнить"),
            cityRelatives2: Yup.string()
              .required("Необходимо заполнить"),
            phoneRelatives2: Yup.string()
              .required("Необходимо заполнить"),
            jobPlaceRelatives2: Yup.string()
              .required("Необходимо заполнить"),
            jobRelatives2: Yup.string()
              .required("Необходимо заполнить"),
            familyWorkInCompany: Yup.string()
              .oneOf(
                ["0", "1"],
                'Выберите один из пунктов выше'
              )
              .required('Необходимо заполнить'),
          `,
            inputs: [
              {
                id: "162",
                config: {
                  type: "relatives",
                }
              },
              {
                label: "Работает/(л) кто-нибудь из ваших родственников/друзей в нашей компании? *",
                initialValue: "",
                id: "163",
                config: {
                  name: "familyWorkInCompany",
                  type: "radio",
                  variants: [
                    {
                      id: "2",
                      name: "Нет"
                    },
                    {
                      id: "1",
                      name: "Да"
                    },
                  ]
                }
              },
            ]
          }
        )
      }
    }

    // для mainForm и miniForm
    payload.push({
      name: "Завершение",
      id: "11",
      inputs: []
    })

    // Получаем профессии для страны
    const professions = axios.get('/professions')
    // Получаем Точки для страны
    const workplaces = axios.get('/workplaces', {
      params: {
        form_id: formId
      }
    })

    // Делаем запрос и записываем в inputs
    await Promise.all([professions, workplaces]).then(res => {
      const indexList = payload.findIndex(i => i.name === "Общие сведения");
      const indexProfessions = payload[indexList].inputs.findIndex(i => i.label === "Должность *");
      const indexWorkplaces = payload[indexList].inputs.findIndex(i => i.label === "Номер ТТ *");
      payload[indexList].inputs[indexProfessions].config.options = payload[indexList].inputs[indexProfessions].config.options.concat(res[0].data)
      payload[indexList].inputs[indexWorkplaces].config.options = payload[indexList].inputs[indexWorkplaces].config.options.concat(res[1].data)
    }).catch(() => {
      payload = []
    }).finally(() => {
      hideLoader()
    })
    dispatch({ type: FETCH_DATA, payload });
  };

  const showLoader = () => dispatch({ type: SHOW_LOADER });
  const hideLoader = () => dispatch({ type: HIDE_LOADER });

  const addData = (payload) => {
    dispatch({ type: FETCH_DATA, payload });
  }

  const showAlert = (text, type = "warning") => {
    dispatch({
      type: SHOW_ALERT,
      payload: { text, type },
    });
  };

  const setFilesToContext = (files) => {
    dispatch({
      type: SET_FILES,
      payload: {files},
    });
  };
  const deleteFilesFromContext = (files) => {
    dispatch({
      type: DELETE_FILES,
      payload: {files},
    });
  };

  const hideAlert = () => dispatch({ type: HIDE_ALERT });

  const changeStage = () => {
    dispatch({ type: CHANGE_STAGE })
  }
  const setCheckCountry = (value) => {
    const payload = value
    dispatch({ type: SET_COUNTRY, payload})
  }
  const changeSectionNine = (value) => {
    const payload = value
    dispatch({ type: SHOW_SECTION_NINE, payload})
  }
  const changeRvp = (value) => {
    const payload = value
    dispatch({ type: SHOW_RVP, payload})
  }
  const changeVng = (value) => {
    const payload = value
    dispatch({ type: SHOW_VNG, payload})
  }
  const changeStudy = (value) => {
    const payload = value
    dispatch({ type: SHOW_STUDY, payload})
  }
  const changeShowPatent = (value) => {
    const payload = value
    dispatch({ type: CHANGE_PATENT_SHOW, payload })
  }

  const changeShowNone = (value) => {
    const payload = value;
    dispatch({ type: SHOW_NONE, payload });
  };

  const changeOnlySchool = (value) => {
    const payload = value
    dispatch({ type: CHANGE_ONLY_SCHOOL, payload })
  }
  const changeDidNotWork = (value) => {
    const payload = value
    dispatch({ type: CHANGE_DID_NOT_WORK, payload })
  }

  const changeToBackStage = () => {
    dispatch({ type: CHANGE_TO_BACK_STAGE })
  }

  const setFileLoader = (value) => {
    const payload = value
    dispatch({ type: FILE_LOADER, payload})
  }

  const uploadFile = async (file) => {
    dispatch({type: FILE_LOADER, payload: true})
    try {
      Promise.all([backendService.uploadFiles(file)])
        .then(res => {
          dispatch({type: ADD_FILE_TOKEN, payload: res[0].data})
          dispatch({type: FILE_LOADER, payload: false})
        })
        return "OK"
    } catch (e) {
      dispatch({type: FILE_LOADER, payload: false})
      return "Произошла ошибка при загрузке файла/ов"
    }
  }

  const addFileInData = (file) => {
    dispatch({type: FILE_ADD, payload: file});
  }

  const incCountSentFile = () => {
    dispatch({type: INC_COUNT_SENT_FILES});
  }

  const removeFileInData = (name) => {
    dispatch({type: FILE_REMOVE, payload: name});
  }

  const clearFileInData = () => {
    dispatch({type: FILE_CLEAR });
  }
  

  const signIn = async (login, password) => {
    let res = await backendService.signIn(login, password)
    console.log(res)
    if (res.status === 200) {
        const payload = res.data.token
        dispatch({ type: SIGN_IN, payload })
    } else {
      console.log('Что-то пошло не так, токен не получен')
    }
  }
  const logOut = async () => {
    dispatch({ type: LOG_OUT })
  }


  return (
    <FormContext.Provider
      value={{
        signIn,
        logOut,
        changeRvp,
        changeVng,
        changeStudy,
        fetchData,
        showAlert,
        hideAlert,
        showLoader,
        changeStage,
        uploadFile,
        setFileLoader,
        changeShowPatent,
        changeShowNone,
        changeOnlySchool,
        changeDidNotWork,
        setFilesToContext,
        changeToBackStage,
        changeSectionNine,
        deleteFilesFromContext,
        setCheckCountry,
        addData,
        addFileInData,
        removeFileInData,
        clearFileInData,
        incCountSentFile,
        data: state.data,
        country: state.country,
        files: state.files,
        token: state.token,
        loading: state.loading,
        showRvp: state.showRvp,
        showVng: state.showVng,
        showStudy: state.showStudy,
        fileTokens: state.fileTokens,
        fileLoader: state.fileLoader,
        onlySchool: state.onlySchool,
        didNotWork: state.didNotWork,
        showPatent: state.showPatent,
        showNone: state.showNone,
        alertVisible: state.alertVisible,
        showSectionNine: state.showSectionNine,
        currentPageIndex: state.currentPageIndex,
        filesData: state.filesData,
        countSentFiles: state.countSentFiles,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
