import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Listbox, Portal, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import {
    CLOSE_REVISE,
    GET_COMPANY,
    GET_PROFILE,
    GET_REVISE,
    GET_WORKPLACES,
    UPDATE_SWITCH_COMPANY
} from '../../graphql/Queries';
import { useParams, useHistory } from 'react-router-dom';
import { hideSmallAlert, showSmallAlert } from "../../redux/alert/alertActions"
import { connect } from "react-redux";
import { withRouter } from "react-router"

export function SwitchCompany(props) {
    const history = useHistory();
    const params = useParams();
    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });
    const [updateSwitchCompany] = useMutation(UPDATE_SWITCH_COMPANY)
    const [getWorkplaces, { error: workplaceError, data: workplaceData }] = useLazyQuery(GET_WORKPLACES);
    if (workplaceError) console.log('workplaceError', workplaceError);

    const [value, setValue] = useState(null);
    const [selectedWorkplace, setSelectedWorkplace] = useState(null);

    const { error, data } = useQuery(GET_COMPANY, {
        fetchPolicy: 'no-cache',
    });
    if (error) console.log(error);

    const companies = useMemo(() => {
        if (data && data.Companies) {
            const currentCompanyName = localStorage.getItem('company_name');
            return data.Companies.filter((company) => company.name !== currentCompanyName);
        } else {
            return [];
        }
    }, [data]);
    const workplaces = useMemo(() => {
        if (workplaceData && workplaceData.Workplace) {
            return workplaceData.Workplace;
        } else {
            return [];
        }
    }, [workplaceData]);

    const updateWorkplace = async () => {
        const foundCompany = companies.find((company) => company.name === value);
        if (foundCompany){
            getWorkplaces({
                fetchPolicy: 'no-cache',
                variables: {
                    company_id: +foundCompany['company_id'],
                }
            })
        }
    };

    useEffect(() => {
        updateWorkplace();
    }, [value]);

    const updateCompany = async () => {
        const foundCompany = companies.find((company) => company.name === value);
        const foundWork = workplaces.find((workplace) => workplace.title === selectedWorkplace);
        await updateSwitchCompany({
            variables: {
                profileId: +params.id,
                companyId: +foundCompany['company_id'],
                workplaceId: +foundWork['workplace_id'],
            },
            refetchQueries: [
                {
                    query: GET_PROFILE, variables: {
                        companyId: +foundCompany['company_id'],
                        profileId: +params.id
                    }
                }
            ],
            awaitRefetchQueries: true
        })
        props.showSmallAlert("Сотрудник переведен", "success");
        setTimeout(() => {
            props.hideSmallAlert();
            window.history.go(-1);
        }, 3000);
    }

    return (
        <>
            <div className="flex flex-col w-full p-4 rounded-lg border bg-white shadow-x-1 my-4">
                <div className="px-1 py-1 flex justify-center sm:px-2">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Сменить компанию</h3>
                    </div>
                </div>
                <div className="rounded-lg border bg-white shadow-x-1 my-1 z-3">
                    <div className="flex-row px-2 py-2" ref={setReferenceElement}>
                        <Listbox value={value} onChange={setValue}>
                            <div className="relative">
                                <Listbox.Button className="w-full flex-row px-1 py-1">
                                    <span className="block truncate">{value || 'Выберите компанию'}</span>
                                    <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                  </span>
                                </Listbox.Button>
                                <Portal>
                                    <Transition
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        {companies && companies.length > 0 ? (
                                            <Listbox.Options
                                                className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                ref={setPopperElement}
                                                style={{ ...styles.popper, width: '18.3%' }}
                                                {...attributes.popper}
                                            >
                                                {companies.map((company, companyIdx) => {
                                                    return (
                                                        <Listbox.Option
                                                            key={companyIdx}
                                                            className={({ active }) =>
                                                                `${
                                                                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'
                                                                } cursor-pointer select-none relative py-2 pl-10 pr-4`
                                                            }
                                                            value={company.name}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                  <span
                                      className={`${
                                          selected ? 'font-medium' : 'font-normal'
                                      } block truncate`}
                                  >
                                    {company.name}
                                  </span>
                                                                    {selected ? (
                                                                        <span
                                                                            className={`${
                                                                                active ? 'text-gray-600' : 'text-gray-600'
                                                                            } absolute inset-y-0 left-0 flex items-center pl-3`}
                                                                        >
                                      <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                                    </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    );
                                                })}
                                            </Listbox.Options>
                                        ) : null}
                                    </Transition>
                                </Portal>
                            </div>
                        </Listbox>
                    </div>
                </div>

                {value && (
                    <div className="rounded-lg border bg-white shadow-x-1 my-1 z-2">
                        <div className="flex-row px-2 py-2" ref={setReferenceElement}>
                            <Listbox value={selectedWorkplace} onChange={setSelectedWorkplace}>
                                <div className="relative">
                                    <Listbox.Button className="w-full flex-row px-1 py-1">
                                        <span className="block truncate">{selectedWorkplace || 'Выберите точку'}</span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
            </span>
                                    </Listbox.Button>

                                    <Portal>
                                        <Transition
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            {workplaces && workplaces.length > 0 ? (
                                                <Listbox.Options
                                                    className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                    ref={setPopperElement}
                                                    style={{...styles.popper, width: '18.3%'}}
                                                    {...attributes.popper}
                                                >
                                                    {/* Добавлена сортировка по title */}
                                                    {[...workplaces]
                                                        .sort((a, b) => a.title.localeCompare(b.title))
                                                        .map((workplace) => (
                                                            <Listbox.Option
                                                                key={workplace.workplace_id}
                                                                className={({active}) =>
                                                                    `${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'} 
                          cursor-pointer select-none relative py-2 pl-10 pr-4`
                                                                }
                                                                value={workplace.title}
                                                            >
                                                                {({selected, active}) => (
                                                                    <>
                            <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                              {workplace.title}
                            </span>
                                                                        {selected && (
                                                                            <span className={`${active ? 'text-gray-600' : 'text-gray-600'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                              </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                </Listbox.Options>
                                            ) : null}
                                        </Transition>
                                    </Portal>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                )}

                <button
                    onClick={updateCompany}
                    type="button"
                    className="w-full mt-2 py-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>
                    Перевести
                </button>
            </div>
        </>
    );
}

const mapDispatchToProps = {
    showSmallAlert,
    hideSmallAlert
}
export default connect(null, mapDispatchToProps)(withRouter(SwitchCompany));
